<div noRightClick class="padded-section">
  <div>
    <div style="text-align: center; background-size: auto 100%;">
      <a href="#" (click)="goToDefaultShop()" [routerLink]="'/shop'">
      <picture>
        <!-- <source srcset="../../assets/home_img.webp" type="image/webp"> -->
        <source srcset="../../assets/home_img.jpg" type="image/jpeg">
        <img class="home-image" [ngStyle]="(this.essentialServices.isMobile | async)? {'height' : '408px'} : {}" src="../../assets/home_img.jpg"  alt="Kalimann Home Image">
      </picture>
    </a>
    </div>
    &nbsp;
    &nbsp;
    <!-- <br *ngIf="!(this.essentialServices.isMobile | async)" /> -->
    <div class="basicCss">
      <h3 style="letter-spacing: 4px; font-size: 24px;" [ngStyle]="(this.essentialServices.isMobile | async)? {'font-size' : '22px'} : {'font-size' : '20px'}" class="mat-h3 basicCss">HANDCRAFTED POTS</h3>
      <h4 style="font-family: 'Libre Baskerville', serif; letter-spacing: 0.3px;" class="mat-h3 basicCss"><i>by Kashmira Shreeram</i></h4>
      <h3 style="line-height: 28px; padding-right: 16px; padding-left: 16px;" class="mat-h4 basicCss">Kalimann Studio was born out of a vision to infuse moments of joy and beauty into everyday ordinary life, through the creation of functional but playful pottery. <br *ngIf="!(this.essentialServices.isMobile | async)" /> 
      I make most of my pots on the wheel using a light coloured stoneware body sourced from Gujarat, India and experiment with local materials for slips and glazes. <br *ngIf="!(this.essentialServices.isMobile | async)" />  Aesthetically, I am drawn to understated forms that balance the softness of the medium with functional, defined shapes. <br *ngIf="!(this.essentialServices.isMobile | async)" />All pots are currently gas-fired in reduction from 1285°C to 1290°C, resulting in glazes that are quieter, but have more depth allowing the inherent qualities of the clay to emerge.
      </h3>
      <h3 style="font-family: 'Libre Baskerville', serif; letter-spacing: 0.3px;" class="mat-h3 basicCss">Kalimann; 'play clay' in Tamizh </h3>

      <!-- <h4 class="mat-h4 basicCss">Wheel thrown and hand built.</h4>
      <h4 class="mat-h4 basicCss">Trying to use local materials as slips and part of glazes. Developing ash glazes.</h4>
      <h4 class="mat-h4 basicCss">Currently gas firing, cone 10 reduction.</h4> -->
    </div>
    &nbsp;
    &nbsp;
    &nbsp;
    &nbsp;
    <mat-divider></mat-divider>
    <br/>
    <!-- <h3 class="mat-h3 basicCss">There is something for everyone.</h3> -->
    <br *ngIf="!(this.essentialServices.isMobile | async)" />
    <mat-card appearance="outlined" class="flex-container-vertical basicCss">
      <br/>
      <br/>
      <h3 class="mat-card-header basicCss">Classes</h3>
      <h3 style="line-height: 28px; padding-right: 16px; padding-left: 16px;" class="mat-h4 basicCss" >
        I share my joy of making, through teaching and conducting weekly classes based out of my home studio. <br *ngIf="!(this.essentialServices.isMobile | async)" /> Whether you are a beginner trying your hand at claywork for the first time, or an already experienced potter looking to refresh your skills, everyone is welcome! <br *ngIf="!(this.essentialServices.isMobile | async)" />For any inquiries, please send in a message and I would be happy to share more details with you :)
      </h3>
      <br/>
      <mat-card-content>
      <div class="flex-container-horizontal">
        <div style="flex-grow: 0.4;" (click)="openClassesDialog('wheel throwing.')">
          <div class="image-container" [ngStyle]="(this.essentialServices.isMobile | async)? {'height' : '200px'} : {'max-height': '45vh'}" >
            <img mat-card-image style="object-fit: fill;" src="../../assets/homeWheel.jpg" alt="Wheel Throwing">
          </div>
          &nbsp;
          <h3 style="font-size: 18px;" class="mat-h3 basicCss">Wheel Throwing</h3>
        </div>
        <div style="flex-grow: 0.4;" (click)="openClassesDialog('hand building.')">
          <div class="image-container" [ngStyle]="(this.essentialServices.isMobile | async)? {'height' : '200px'} : {'max-height': '45vh'}" >
            <img mat-card-image style="object-fit: fill;" src="../../assets/handBuilding.jpg" alt="Hand Building">
          </div>
          &nbsp;
          <h3 style="font-size: 18px;" class="mat-h3 basicCss">Hand Building</h3>
        </div>
      </div>
    </mat-card-content>
    </mat-card>
    <br/>
    <mat-card appearance="outlined">
      <br/>
      <br/>
      <h3  class="mat-card-header basicCss">Newsletters</h3>
      <br/>
      <mat-card-content>
      <div style="text-align: center;">
      <form [formGroup]="emailForm" class="basicCss">
        <mat-form-field appearance="outline">
          <mat-label>Your email</mat-label>
          <input #emailInput matInput type="email" formControlName="email" placeholder="Type here">
            <mat-error *ngIf="emailForm.controls.email.errors?.required">Email address is required to subscribe</mat-error>
            <mat-error *ngIf="emailForm.controls.email.errors?.pattern">Please enter a valid email address</mat-error>
        </mat-form-field>
      </form>
      <br/>
      <button style="align-self: center;" [disabled]="!emailForm.valid" class="big-button" mat-stroked-button (click)="subscribeToNewsLetters()">Subscribe</button>
      </div>
    </mat-card-content>
    </mat-card>

  </div>
</div>
