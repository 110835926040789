<div [ngClass]="essentialsService.marginClass" >
<mat-card appearance="outlined">
<mat-card-content>
  <div class="flex-container-horizontal" style="justify-content: center; align-items: center;">
    <div *ngIf="!(essentialsService.isMobile | async)" style="flex-grow: 0.1;"></div>
    <div style="flex-grow: 0.1; align-items: center;">
      <img mat-card-lg-image src="../../assets/machiProfilePic.jpg" appDisableRightClick style="clip-path: circle(); align-self: flex-start; justify-self: center;" alt="Kashmira Shreeram">
    </div>
    <div class="flex-container-vertical" style="flex-grow: 0.1; align-items: flex-start;">
        <mat-card-content>
        <h3 class="mat-card-header">
          About me
        </h3>        
      <h4 style="line-height: 18px;" class="mat-h4">I first began to get seriously interested in pottery in 2015, during the several months that I spent in Auroville as an architecture trainee, where I was learning how to work with earth as a building material. I had the good fortune of interacting with several potters based there and started developing a fondness for clay as a medium and how much self expression it allowed. I was extremely drawn to the tactile, hands-on nature of the craft and the thought of being able to transform a ball of clay into a tangible object that could bring joy into the everyday life of someone, delighted me. </h4>
      <h4 style="line-height: 18px;" class="mat-h4">I began my formal training in pottery at Golden Bridge Pottery, Pondicherry in 2017, where I trained rigorously for eight months in wheel throwing various forms. This intensive experience helped build a firm foundation for a series of apprenticeships that followed; I trained with Pune-based ceramicist Veena Chandran for ten months (2017-2018), and also completed a UK-based apprenticeship (April - August 2019) with Mike Dodd in Somerset and Micki Schloessingk in Cheriton. Through these experiences I was able to get insight into the intricacies of establishing and running a functioning studio, work in depth with techniques of glazing and firing and also explore new forms and ways of personal self expression.</h4>
      <h4 style="line-height: 18px;" class="mat-h4">I founded Kalimann Studio in 2018 after setting up my own studio space at Chinchwad Pune. This continues to be my creative base from where I currently create, teach and constantly evolve and explore my own relationship with the craft.</h4>
      <h4 style="line-height: 18px;" class="mat-h4">Set up studio space at Chinchwad, Pune in November 2018</h4>
      <h4 style="line-height: 18px;" class="mat-h4">Apprenticed with Mike Dodd and Micki Schloessingk (April 2019-August 2019)</h4>
      </mat-card-content>
    </div>
    <div *ngIf="!(essentialsService.isMobile | async)" style="flex-grow: 0.4;"></div>
    </div>
  </mat-card-content>
</mat-card>
</div>
